import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import produtoStyles from './produto.module.scss'

// A resposta desta query é colocada automaticamente
// no props do template
export const query = graphql`
  query (
    $slug: String!
  ) {
    markdownRemark (
      fields: {
        slug: {
          eq: $slug
        }
      }
    ) {
      frontmatter {
        title
        order
        productImage {
          childImageSharp {
            fluid (quality: 50 , maxWidth: 1920){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    },
  }
`

const Produtos = (props) => {
  return (
    <Layout>
      <div className={produtoStyles.mainContent}>
        <div className={produtoStyles.produtoTexto}>
          <h1>{props.data.markdownRemark.frontmatter.title}</h1>
        </div>
      </div>
      <Img fluid={props.data.markdownRemark.frontmatter.productImage.childImageSharp.fluid} />

      <div className={produtoStyles.mainContent}>
        <div
          className={produtoStyles.markdownContent}
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html}}
        >
        </div>
      </div>
    </Layout>
  )
}

export default Produtos